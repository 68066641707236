import { fwenbenUploader, glanceView } from "@lib";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["blockquote", "code-block"], // 引用  代码块
  // [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  [{ script: "sub" }, { script: "super" }], // 上标/下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进  2
  [
    {
      color: [
        "#ffffff",
        "#ffd7d5",
        "#ffdaa9",
        "#fffed5",
        "#d4fa00",
        "#73fcd6",
        "#a5c8ff",
        "#ffacd5",
        "#ff7faa",
        "#d6d6d6",
        "#ffacaa",
        "#ffb995",
        "#fffb00",
        "#73fa79",
        "#00fcff",
        "#78acfe",
        "#d84fa9",
        "#ff4f79",
        "#b2b2b2",
        "#d7aba9",
        "#ff6827",
        "#ffda51",
        "#00d100",
        "#00d5ff",
        "#0080ff",
        "#ac39ff",
        "#ff2941",
        "#888888",
        "#7a4442",
        "#ff4c00",
        "#ffa900",
        "#3da742",
        "#3daad6",
        "#0052ff",
        "#7a4fd6",
        "#d92142",
        "#000000",
        "#7b0c00",
        "#ff0000",
        "#d6a841",
        "#407600",
        "#007aaa",
        "#021eaa",
        "#797baa",
        "#ab1942",
      ],
    },
    {
      background: [
        "#ffffff",
        "#ffd7d5",
        "#ffdaa9",
        "#fffed5",
        "#d4fa00",
        "#73fcd6",
        "#a5c8ff",
        "#ffacd5",
        "#ff7faa",
        "#d6d6d6",
        "#ffacaa",
        "#ffb995",
        "#fffb00",
        "#73fa79",
        "#00fcff",
        "#78acfe",
        "#d84fa9",
        "#ff4f79",
        "#b2b2b2",
        "#d7aba9",
        "#ff6827",
        "#ffda51",
        "#00d100",
        "#00d5ff",
        "#0080ff",
        "#ac39ff",
        "#ff2941",
        "#888888",
        "#7a4442",
        "#ff4c00",
        "#ffa900",
        "#3da742",
        "#3daad6",
        "#0052ff",
        "#7a4fd6",
        "#d92142",
        "#000000",
        "#7b0c00",
        "#ff0000",
        "#d6a841",
        "#407600",
        "#007aaa",
        "#021eaa",
        "#797baa",
        "#ab1942",
      ],
    },
  ], // 字体颜色、字体背景颜色
  [{ size: ["small", false, "large", "huge"] }], // 字体大小 2
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ font: [] }], // 字体种类 2
  [{ direction: "rtl" }], // 文本方向  2
  [{ align: [] }], // 对齐方式 2
  ["clean"], // 清除文本格式
  ["link", "image", "video"], // 链接、图片、视频
];
export default {
  name: "text-editer",
  props: {
    /*编辑器的内容*/
    value: null,
    /*图片大小*/
    maxSize: {
      type: Number,
      default: 4000, //kb
    },
    refName:{
      type:String,
      default:''
    },
    /* 编辑器的索引 */
    quillIndex: {
      type: Number,
      default: 0
    },
  },

  components: {
    quillEditor
  },
  watch: {
    value() {
      this.content = this.value;
    },
  },

  data() {
    return {
      content: this.value,
      quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      editorOption: {
        theme: "snow", // or 'bubble'
        placeholder: "请输入产品解决方案内容",
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: (value)=> {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelectorAll(".avatar-uploader input")[this.quillIndex].click();
                } else {
                  this.quill.format("image", false);
                }
              },
              // link: function(value) {
              //   if (value) {
              //     var href = prompt('请输入url');
              //     this.quill.format("link", href);
              //   } else {
              //     this.quill.format("link", false);
              //   }
              // },
            },
          },
        },
      },
      serverUrl: "", // 这里写你要上传的图片服务器地址
      header: {
        token: sessionStorage.getItem("token"),
      },
    };
  },

  methods: {
    goodNewPicFn(val) {
      let formD = new FormData();
      formD.append("file", val.file);
      fwenbenUploader(formD).then((res) => {
        let oldContent = this.content;
        if (res.success) {
          this.$message.success(res.msg);

          let imgContent = `<img src='${window.IMGURL}/fileUploader/download?id=${res.id}' >`;

          if (oldContent == undefined) {
            oldContent = "";
          }

          this.content = oldContent + imgContent;
        } else {
          this.content = oldContent;
        }
      });
    },
    onEditorChange({ html }) {
      this.content = html;
      //内容改变事件
      this.$emit("textareaData", this.content);
    },

    // 富文本图片上传前
    beforeUpload() {
      // 显示loading动画
      // this.quillUpdateImg = true;
    },
    randomId (len) {
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var tempLen = chars.length, tempStr = '';
      for (var i = 0; i < len; ++i) {
        tempStr += chars.charAt(Math.floor(Math.random() * tempLen));
      }
      return tempStr;
    },
    uploadSuccess(res) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      let quill = this.$refs[this.refName || 'myQuillEditor'].quill;
      // 如果上传成功
      if (res.error == 0) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", res.info.img_url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      // loading动画消失
      this.quillUpdateImg = false;
    },
    // 富文本图片上传失败
    uploadError() {
      // loading动画消失
      this.quillUpdateImg = false;
      this.$message.error("图片插入失败");
    },
  },
  async created() {
    if (
      this.$route.params.id !== "undefined" &&
      this.$route.params.id !== undefined
    ) {
      console.log(this.$route.params.id);
      let res = await glanceView({ id: this.$route.params.id });
      this.content = res.result.content;
    }
  },
};
